import { authRoles } from './auth/authRoles';

export const navigations = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: 'dashboard',
    auth: [authRoles.sa, authRoles.orgUser],
  },
  {
    label: 'Management',
    type: 'label',
    auth: [authRoles.sa, authRoles.orgUser],
  },
  {
    name: 'Organizations',
    icon: 'home',
    children: [
      { name: 'List Organization', iconText: 'O', path: '/organizations' },
      {
        name: 'Create Organization',
        iconText: 'OC',
        path: '/organization/create',
      },
    ],
    auth: authRoles.sa,
  },
  {
    name: 'Users',
    icon: 'people',
    children: [
      { name: 'List Users', iconText: 'L', path: '/organization/users' },
      {
        name: 'Users Requests',
        iconText: 'UR',
        path: '/organization/request/users',
      },
    ],
    auth: authRoles.orgUser,
  },
  {
    name: 'Geo fences',
    icon: 'map',
    children: [
      {
        name: 'List Geo Fences',
        iconText: 'O',
        path: '/organization/geo-fences',
      },
      {
        name: 'Create Geo Fences',
        iconText: 'OC',
        path: '/organization/create/geo-fences',
      },
    ],
    auth: authRoles.orgUser,
  },
  {
    name: 'Time Windows',
    icon: 'map',
    children: [
      {
        name: 'List Time Windows',
        iconText: 'O',
        path: '/organization/time-windows',
      },
      {
        name: 'Create New Time Window',
        iconText: 'TW',
        path: '/organization/create/time-windows',
      },
    ],
    auth: authRoles.orgUser,
  },
  {
    name: 'Settings',
    icon: 'settings',
    children: [
      { name: 'Auth0', iconText: 'O', path: '/organization/auth0-settings' },
    ],
    auth: authRoles.orgUser,
  },
  { label: 'PAGES', type: 'label' },
  {
    name: 'Session/Auth',
    icon: 'security',
    children: [
      { name: 'Sign in', iconText: 'SI', path: '/signin' },
      { name: 'Sign up', iconText: 'SU', path: '/signup' },
      { name: 'Forgot Password', iconText: 'FP', path: '/forgot-password' },
      { name: 'Error', iconText: '404', path: '/404' },
    ],
  },
  { label: 'Components', type: 'label' },
  {
    name: 'Components',
    icon: 'favorite',
    badge: { value: '30+', color: 'secondary' },
    children: [
      { name: 'Auto Complete', path: '/material/autocomplete', iconText: 'A' },
      { name: 'Buttons', path: '/material/buttons', iconText: 'B' },
      { name: 'Checkbox', path: '/material/checkbox', iconText: 'C' },
      { name: 'Dialog', path: '/material/dialog', iconText: 'D' },
      {
        name: 'Expansion Panel',
        path: '/material/expansion-panel',
        iconText: 'E',
      },
      { name: 'Form', path: '/material/form', iconText: 'F' },
      { name: 'Icons', path: '/material/icons', iconText: 'I' },
      { name: 'Menu', path: '/material/menu', iconText: 'M' },
      { name: 'Progress', path: '/material/progress', iconText: 'P' },
      { name: 'Radio', path: '/material/radio', iconText: 'R' },
      { name: 'Switch', path: '/material/switch', iconText: 'S' },
      { name: 'Slider', path: '/material/slider', iconText: 'S' },
      { name: 'Snackbar', path: '/material/snackbar', iconText: 'S' },
      { name: 'Table', path: '/material/table', iconText: 'T' },
    ],
  },
  {
    name: 'Charts',
    icon: 'trending_up',
    children: [{ name: 'Echarts', path: '/charts/echarts', iconText: 'E' }],
  },
  {
    name: 'Documentation',
    icon: 'launch',
    type: 'extLink',
    path: 'http://demos.ui-lib.com/matx-react-doc/',
  },
];
