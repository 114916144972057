import AuthGuard from '../app/auth/AuthGuard';
import chartsRoute from '../app/views/charts/ChartsRoute';
import dashboardRoutes from './views/dashboard/DashboardRoutes';
import materialRoutes from './views/material-kit/MaterialRoutes';
import organizationRoutes from './views/organizations/OrganizationRoutes';
import NotFound from '../app/views/sessions/NotFound';
import sessionRoutes from './views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from '../app/components/MatxLayout/MatxLayout';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...chartsRoute,
      ...materialRoutes,
      ...organizationRoutes,
    ],
    basename: '/services/portal',
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to={'/dashboard'} /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
