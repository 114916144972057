export const SET_USER_NAVIGATION = 'SET_USER_NAVIGATION';

export const getfilteredNavigations = (navList = [], role) => {
  return navList.reduce((array, nav) => {
    if (Array.isArray(nav.auth)) {
      nav.auth.forEach(function (item) {
        if (item !== undefined) {
          if (item) {
            if (item.includes(role)) {
              array.push(nav);
            }
          } else {
            if (nav.children) {
              nav.children = getfilteredNavigations(nav.children, role);
              array.push(nav);
            } else {
              array.push(nav);
            }
          }
        }
      });
    } else {
      if (nav.auth !== undefined) {
        if (nav.auth) {
          if (nav.auth.includes(role)) {
            array.push(nav);
          }
        } else {
          if (nav.children) {
            nav.children = getfilteredNavigations(nav.children, role);
            array.push(nav);
          } else {
            array.push(nav);
          }
        }
      }
    }
    return array;
  }, []);
};

export function getNavigationByUser() {
  return (dispatch, getState) => {
    let { user, navigations = [] } = getState();

    let filteredNavigations = getfilteredNavigations(navigations, user.role);

    dispatch({
      type: SET_USER_NAVIGATION,
      payload: [...filteredNavigations],
    });
  };
}
