import { Card } from '@mui/material';
import { Box, styled } from '@mui/system';

const CardRoot = styled(Card)(() => ({
  height: '100%',
  padding: '20px 24px'
}));

const CardTitle = styled('div')(({ subtitle }) => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize',
  marginBottom: !subtitle && '16px',
}));

const CardBody = styled('div')(({is_table = false}) => (!is_table && {
  display: 'flex',
  justifyContent : 'center',
  alignItems : 'center',
  minHeight: '300px'
}));

const CardComponent = ({ children, title, subtitle, icon, table = false }) => {
  return (
    <CardRoot elevation={6}>
      <CardTitle subtitle={subtitle}>{title}</CardTitle>
      {subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
      <CardBody is_table={table ? 0 : 1}>
        {children}
      </CardBody>
    </CardRoot>
  );
};

export default CardComponent;
