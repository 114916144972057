import Loadable from '../../components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const OrganizationTable = Loadable(lazy(() => import('./main/Index')));
const OrganizationForm = Loadable(lazy(() => import('./main/Form')));
const OrganizationEditForm = Loadable(lazy(() => import('./main/EditForm')));

const OrgUserTable = Loadable(lazy(() => import('./organization-users/Index')));
const OrgUserReqTable = Loadable(
  lazy(() => import('./organization-users-req/Index')),
);
const OrgUserForm = Loadable(lazy(() => import('./organization-users/Form')));

const OrgGeoFencesTable = Loadable(
  lazy(() => import('./organization-geo-fences/Index')),
);
const OrgGeoFencesForm = Loadable(
  lazy(() => import('./organization-geo-fences/Form')),
);
const OrgGeoFencesEditForm = Loadable(
  lazy(() => import('./organization-geo-fences/EditForm')),
);

const OrgTimeWindowTable = Loadable(
  lazy(() => import('./organization-time-windows/Index')),
);
const OrgTimeWindowForm = Loadable(
  lazy(() => import('./organization-time-windows/Form')),
);
const OrgTimeWindowEditForm = Loadable(
  lazy(() => import('./organization-time-windows/EditForm')),
);

const Auth0SettingsForm = Loadable(lazy(() => import('./settings/auth0/Form')));

const organizationRoutes = [
  {
    path: '/organizations',
    element: <OrganizationTable />,
    auth: authRoles.admin,
  },
  {
    path: '/organization/create',
    element: <OrganizationForm />,
    auth: authRoles.admin,
  },
  {
    path: '/organization/edit',
    element: <OrganizationEditForm />,
    auth: authRoles.admin,
  },
  {
    path: '/organization/users',
    element: <OrgUserTable />,
    auth: authRoles.orgUser,
  },
  {
    path: '/organization/request/users',
    element: <OrgUserReqTable />,
    auth: authRoles.orgUser,
  },
  {
    path: '/organization/edit/user',
    element: <OrgUserForm />,
    auth: authRoles.orgUser,
  },
  {
    path: '/organization/geo-fences',
    element: <OrgGeoFencesTable />,
    auth: authRoles.orgUser,
  },
  {
    path: '/organization/create/geo-fences',
    element: <OrgGeoFencesForm />,
    auth: authRoles.orgUser,
  },
  {
    path: '/organization/edit/geo-fences',
    element: <OrgGeoFencesEditForm />,
    auth: authRoles.orgUser,
  },
  {
    path: '/organization/time-windows',
    element: <OrgTimeWindowTable />,
    auth: authRoles.orgUser,
  },
  {
    path: '/organization/create/time-windows',
    element: <OrgTimeWindowForm />,
    auth: authRoles.orgUser,
  },
  {
    path: '/organization/edit/time-window',
    element: <OrgTimeWindowEditForm />,
    auth: authRoles.admin,
  },
  {
    path: '/organization/auth0-settings',
    element: <Auth0SettingsForm />,
    auth: authRoles.orgUser,
  },
];

export default organizationRoutes;
